html, body, #root, .App {
  height: 100%;
  overflow: auto;
}
.App {
  text-align: center;
}

a {
  text-decoration: none;
  color: inherit;
}
